// Add blockers has some problem with the api name start with ad
export const locations = {
  get: {
    url: "place/get",
    method: "GET",
    id: null,
    params: null,
  },
  create: {
    url: "place/create",
    method: "POST",
    data: null,
  },
  update: {
    url: "place/update/",
    method: "PUT",
    id: null,
    data: null,
  },
  delete: {
    url: "place/delete/",
    method: "DELETE",
    id: null,
  },
};
